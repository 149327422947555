export default [
  {key: 'dropbox', name: 'Dropbox', text: 'Dropbox Storage', image: 'dropbox-ico.png', link: ''},
  {key: 'aws-s3', name: 'Amazon (AWS)', text: 'AWS S3', image: 'aws-ico.png', link: ''},
  {key: 'google-cloud-storage', name: 'Google', text: 'Google Cloud Storage', image: 'google-cloud-ico.png', link: ''},
  {key: 'google-drive', name: 'Google', text: 'Google Drive', image: 'googledrive-ico.png', link: ''},
  {key: 'backblaze', name: 'Backblaze', text: 'Backblaze B2', image: 'backblaze-ico.png', link: ''},
  {key: 'digitalocean', name: 'DigitalOcean', text: 'DigitalOcean Spaces', image: 'digitalocean-ico.png', link: ''},
  {key: 'wasabi', name: 'Wasabi', text: 'Object Storage', image: 'wasabi-ico.png', link: ''},
  {key: 'filebase', name: 'Filebase', text: 'Filebase', image: 'filebase-ico.png', link: ''},
  {key: 'azure', name: 'Microsoft Azure', text: 'Azure Blob Storage', image: 'azure-ico.png', link: ''},
  {key: 'alibaba', name: 'Alibaba Cloud', text: 'Alibaba Cloud OSS', image: 'alibaba-ico.png', link: ''},
  {key: 'synology', name: 'Synology', text: 'Object Storage', image: 'synology-ico.png', link: ''},
  {key: 'exoscale', name: 'Exoscale', text: 'Object Storage', image: 'exoscale-ico.png', link: ''},
  {key: 'upcloud', name: 'Upcloud', text: 'Object Storage', image: 'upcloud-ico.png', link: ''},
  {key: 'idrive-e2', name: 'IDrive e2', text: 'Object Storage', image: 'idrive-e2-ico.png', link: ''},
  {key: 'cloudflare-r2', name: 'Cloudflare', text: 'R2 Storage', image: 'cloudflare-ico.png', link: ''},
  {key: 'minio', name: 'MinIO', text: 'Object Storage', image: 'minio-ico.png', link: ''},
  {key: 'storadera', name: 'Storadera', text: 'Object Storage', image: 'storadera-ico.png', link: ''},
  {key: 'leviia', name: 'Leviia', text: 'Object Storage', image: 'leviia-ico.png', link: ''},
  {key: 'outscale', name: 'Outscale', text: 'Object Storage', image: 'outscale-ico.png', link: ''},
  {key: 'simplestorage', name: 'SimpleStorage', text: 'Object Storage', image: 'simple-ico.png', link: ''},
  {key: 'storj', name: 'Storj', text: 'Object Storage', image: 'storj-ico.png', link: ''},
]

const meta = {
  'title': 'Automated file, database, storage and application backup stored on your ##provider##',
  'description': 'Store your file, database, storage and application backup on your ##provider##. All regions supported.',
}

export { meta }